import React from 'react';
import { RouteProps, Switch } from 'react-router-dom';
import { ProtectedRoute } from 'components/ProtectedRoute/ProtectedRoute';
import { ROUTES } from 'config/routes';
import { ForumHomePage } from './ForumHomePage/ForumHomePage';
import { ForumPostPage } from './ForumPostPage/ForumPostPage';

export const ForumRoutes: React.FC<RouteProps> = () => (
  <Switch>
    <ProtectedRoute exact path={ROUTES.FORUM} component={ForumHomePage} />
    <ProtectedRoute exact path={ROUTES.FORUM_CATEGORY} component={ForumHomePage} />
    <ProtectedRoute exact path={ROUTES.FORUM_POST} component={ForumPostPage} />
  </Switch>
);
