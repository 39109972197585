import React, { useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Loading } from 'components/Loading/Loading';
import { FormattedMessage, useIntl } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { useApi } from 'api/ApiContext';
import { useForumPostData } from '../hooks/useForumPostData';
import { PostDetails } from '../components/PostDetails/PostDetails';
import { CategoriesSidebar } from '../components/CategoriesSidebar/CategoriesSidebar';
import styles from './ForumPostPage.module.scss';
import { messages } from './messages';

interface RouteParams {
  id: string;
}

export function ForumPostPage() {
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const api = useApi();
  const { formatMessage } = useIntl();

  const { categories, post, comments, setComments, isLoading, error } = useForumPostData(id);

  const handleVote = useCallback(
    async (postId: string, type: 'upvote' | 'downvote') => {
      try {
        await api.forum.createReaction(postId, type);
      } catch (err) {
        console.error('Error submitting vote:', err);
      }
    },
    [api.forum]
  );

  const handleCreateComment = async (content: string, parentId: string) => {
    if (!post) return;
    try {
      const response = await api.forum.createPost({
        content,
        parentId: parentId || post.id,
      });
      const newComment = response.data.data;
      setComments(prev => [...prev, newComment]);
    } catch (err) {
      console.error('Error creating comment:', err);
    }
  };

  const handleCategorySelect = (catId: string | null) => {
    if (catId) {
      history.push(`/forum/categories/${catId}/posts`);
    } else {
      history.push('/forum');
    }
  };

  if (error) {
    return (
      <div>
        <FormattedMessage {...messages.errorLoadingPost} values={{ error: error.message }} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Helmet title={formatMessage(messages.forumTitle)} />
      <section className={styles.headingContainer}>
        <div className={styles.headingContentWrapper}>
          <div>
            <h1 className={styles.headingTitle}>
              <span>
                <FormattedMessage {...messages.pageTitle} />
              </span>
            </h1>
            <h5 className={styles.headingSubtitle}>
              <FormattedMessage {...messages.headingSubtitle} />
            </h5>
          </div>
          <p className={styles.headingDescription}>
            <FormattedMessage {...messages.headingDescription} />
          </p>
        </div>
      </section>
      <div className={styles.content}>
        <Loading isLoading={isLoading}>
          <div className={styles.mainLayout}>
            <CategoriesSidebar
              categories={categories}
              selectedCategoryId={null}
              onCategorySelect={handleCategorySelect}
            />
            <main className={styles.mainContent}>
              {post ? (
                <PostDetails
                  post={post}
                  comments={comments}
                  handleVote={handleVote}
                  onCommentCreate={handleCreateComment}
                />
              ) : (
                <p>
                  <FormattedMessage {...messages.postNotFound} />
                </p>
              )}
            </main>
          </div>
        </Loading>
      </div>
    </div>
  );
}
