import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { ForumPost } from 'types/forum';
import { ReactComponent as CommentIcon } from 'assets/icons/comment.svg';
import defaultAvatar from 'assets/icons/default-avatar.svg';
import { FormattedMessage } from 'react-intl';

import { ReplyForm } from '../ReplyForm/ReplyForm';
import { VoteGroup } from '../VoteGroup/VoteGroup';

import styles from './Comment.module.scss';
import { messages } from './messages';
import { useApi } from 'api/ApiContext';

interface CommentProps {
  comment: ForumPost;
  level?: number;
  comments: ForumPost[];
  onCommentCreate: (content: string, parentId?: string) => void;
}

export function Comment({ comment, level = 0, comments, onCommentCreate }: CommentProps) {
  const api = useApi();
  const [isReplying, setIsReplying] = useState(false);
  const [showAllReplies, setShowAllReplies] = useState(false);

  const childComments = comments.filter(c => Number(c?.attributes?.parentId) === Number(comment.id));
  const hasChildren = childComments.length > 0;
  const isNested = level > 0;

  const handleVote = useCallback(
    async (postId: string, type: 'upvote' | 'downvote') => {
      try {
        api.forum.createReaction(postId, type).catch(console.error);
      } catch (error) {
        console.error('Error submitting vote:', error);
      }
    },
    [api.forum]
  );

  return (
    <div className={classNames(styles.commentThread, { [styles.nested]: isNested })}>
      <div className={styles.commentCard}>
        <div>
          <div className={styles.authorInfo}>
            <img
              src={comment?.attributes?.relationUser?.data?.attributes?.avatarUrl || defaultAvatar}
              alt="User avatar"
              className={styles.avatar}
            />
            <div className={styles.authorMetadata}>
              <span className={styles.authorName}>
                {comment?.attributes?.postedBy || 'anonymous'}
              </span>
              <span className={styles.timeAgo}>
                <FormattedMessage
                  {...messages.timeAgo}
                  values={{ time: comment.attributes.timeAgo }}
                />
              </span>
            </div>
          </div>

          <div className={styles.content}>{comment?.attributes?.content}</div>

          <div className={styles.commentActions}>
            <VoteGroup
              id={comment.id}
              relationReactions={{
                upvote: comment.attributes?.relationReactions.upvote || 0,
                downvote: comment.attributes?.relationReactions.downvote || 0,
              }}
              handleVote={handleVote}
            />
            {level <= 1 && (
            <button className={styles.actionButton} onClick={() => setIsReplying(!isReplying)}>
              <CommentIcon className={styles.icon} />
              {childComments?.length}
              {hasChildren && (
                <button
                  onClick={e => {
                    e.stopPropagation();
                    setShowAllReplies(!showAllReplies);
                  }}
                  className={styles.showAllButton}
                >
                  {showAllReplies ? ' Hide all...' : ' Show all...'}
                </button>
              )}
            </button>
            )}
          </div>

          {level <= 1  && isReplying && (
            <ReplyForm
              onSubmit={content => {
                onCommentCreate(content, comment.id);
                setIsReplying(false);
              }}
              onCancel={() => setIsReplying(false)}
              replyingTo={comment?.attributes?.postedBy || 'anonymous'}
            />
          )}
        </div>
      </div>

      {showAllReplies && childComments.length > 0 && (
        <div className={styles.replies}>
          {childComments.map(childComment => (
            <Comment
              key={childComment.id}
              comment={childComment}
              level={level + 1}
              comments={comments}
              onCommentCreate={onCommentCreate}
            />
          ))}
        </div>
      )}
    </div>
  );
}
