import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'components/Button/Button';
import { TextField } from 'components/TextField/TextField';

import { DropdownSelectMenu } from 'modules/grants/components/DropdownSelectMenu/DropdownSelectMenu';
import { TextAreaField } from 'components/TextAreaField/TextAreaField';
import { CustomDatePickerDays } from '../../components/CustomDatePickerDays/CustomDatePickerDays';

import { ImageUpload } from './elements/ImageUpload/ImageUpload';
import styles from './NewEventForm.module.scss';
import { messages } from './messages';
import { useApi } from 'api/ApiContext';
import debounce from 'lodash/debounce';
import { CreateEventData } from 'api/EventsClient';
import { OrganizerSelect } from './elements/OrganizerSelect/OrganizerSelect';

interface CountryOption {
  label: string;
  value: string;
  disabled: boolean;
}

interface NewEventFormProps {
  countryOptions: CountryOption[];
  onSubmit?: (values: CreateEventData) => Promise<{
    status: number;
    data?: { message?: string };
  } | void>;
}

const EVENT_FORMATS = [
  { label: 'In-Person', value: 'in_person', disabled: false },
  { label: 'Hybrid', value: 'hybrid', disabled: false },
  { label: 'Remote', value: 'remote', disabled: false },
];

const EVENT_IMAGE_UPLOAD_CONFIG = {
  maxSizeInMB: 5,
  maxSizeInBytes: 5 * 1024 * 1024,
  acceptedFormats: 'JPG, PNG, WebP',
  acceptedFormatsList: ['.jpg', '.jpeg', '.png', '.webp'],
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  organizer: Yup.object()
    .nullable()
    .required('Organizer is required'),
  startDate: Yup.date()
    .nullable()
    .required('Start date is required'),
  endDate: Yup.date()
    .nullable()
    .required('End date is required')
    .min(Yup.ref('startDate'), 'End date must be after start date'),
  urlReference: Yup.string()
    .required('URL is required')
    .url('Must be a valid URL'),
  country: Yup.array().min(1, 'Country is required'),
  eventFormats: Yup.array()
    .min(1, 'At least one format must be selected')
    .max(3, 'Maximum three formats can be selected'),
  description: Yup.string().max(500, 'Description cannot exceed 500 characters'),
});

export const NewEventForm: React.FC<NewEventFormProps> = ({ countryOptions, onSubmit }) => {
  const api = useApi();
  const intl = useIntl();

  const [isEventCreated, setIsEventCreated] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const initialValues: CreateEventData = {
    name: '',
    organizer: null,
    startDate: null,
    endDate: null,
    urlReference: '',
    country: [],
    eventFormats: [],
    description: '',
    image: null,
  };

  const searchInstitutions = async (query: string) => {
    if (query.length < 3) return [];
    try {
      const response = await api.events.searchInstitutions(query);
      return response.data.data;
    } catch (error) {
      console.error('Error searching organizers:', error);
      return [];
    }
  };

  const debouncedSearch = debounce(searchInstitutions, 300);

  const handleSubmit = async (values: CreateEventData) => {
    if (!onSubmit) {
      console.warn('No onSubmit prop provided. Printing values to console:', values);
      return;
    }

    try {
      const result = await onSubmit(values);
      if (result && result.status === 201) {
        setSuccessMessage(
          intl.formatMessage(messages.eventSubmissionSuccess)
        );
        setIsEventCreated(true);
      }
    } catch (err) {
      console.error('Error while submitting event:', err);
    }
  };


  if (isEventCreated) {
    return (
      <div style={{ padding: '2rem', maxWidth: 800, margin: '0 auto' }}>
        <h3>{successMessage || 'Event created.'}</h3>
      </div>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <Form className={styles.form}>
          <h2 className={styles.formTitle}>
            <FormattedMessage {...messages.formTitle} />
          </h2>

          <div className={styles.formField}>
            <label htmlFor="name">
              <FormattedMessage {...messages.nameLabel} />
            </label>
            <TextField
              id="name"
              name="name"
              placeholder={messages.namePlaceholder.defaultMessage}
            />
          </div>

          <div className={styles.formField}>
            <OrganizerSelect
              name="organizer"
              label={messages.organizerLabel.defaultMessage}
              placeholder={messages.organizerPlaceholder.defaultMessage}
              loadOptions={debouncedSearch}
            />
          </div>

          <div className={styles.formField}>
            <CustomDatePickerDays
              selected={values.startDate}
              onChange={date => setFieldValue('startDate', date)}
              onClear={() => setFieldValue('startDate', null)}
              className={styles.searchSelect}
              labelText={messages.startDateLabel.defaultMessage}
              placeHolderText={messages.startDatePlaceholder.defaultMessage}
              dateFormat="yyyy-MM-dd"
              error={errors.startDate !== undefined}
              errorMessage="Start date is required"
            />
          </div>

          <div className={styles.formField}>
            <CustomDatePickerDays
              selected={values.endDate}
              onChange={date => setFieldValue('endDate', date)}
              onClear={() => setFieldValue('endDate', null)}
              className={styles.searchSelect}
              labelText={messages.endDateLabel.defaultMessage}
              placeHolderText={messages.endDatePlaceholder.defaultMessage}
              dateFormat="yyyy-MM-dd"
              error={errors.endDate !== undefined}
              errorMessage="End date is required"
              minDate={values.startDate || undefined}
            />
          </div>

          <div className={styles.formField}>
            <DropdownSelectMenu
              id="country"
              name="country"
              label={messages.countryLabel.defaultMessage}
              query={values.country}
              setQuery={(country: string[]) => setFieldValue('country', country)}
              setFieldValue={setFieldValue}
              placeholder={messages.countryPlaceholder.defaultMessage}
              selectedItems={values.country.length}
              setSelectedItems={() => null}
              options={countryOptions}
              wrapperClass={styles.dropdown}
              submit={() => Promise.resolve()}
            />
          </div>

          <div className={styles.formField}>
            <TextField
              label={messages.urlLabel.defaultMessage}
              name="urlReference"
              placeholder={messages.urlPlaceholder.defaultMessage}
            />
          </div>

          <div className={styles.formField}>
            <DropdownSelectMenu
              id="eventFormats"
              name="eventFormats"
              label={messages.formatsLabel.defaultMessage}
              query={values.eventFormats}
              setQuery={(vals: string[]) => setFieldValue('eventFormats', vals)}
              setFieldValue={setFieldValue}
              placeholder={messages.formatsPlaceholder.defaultMessage}
              selectedItems={values.eventFormats.length}
              setSelectedItems={() => null}
              options={EVENT_FORMATS}
              wrapperClass={styles.dropdown}
              submit={() => Promise.resolve()}
            />
          </div>

          <div className={styles.formField}>
            <TextAreaField
              label={messages.descriptionLabel.defaultMessage}
              name="description"
              placeholder={messages.descriptionPlaceholder.defaultMessage}
              rows={4}
            />
          </div>

          <ImageUpload
            value={values.image}
            onChange={file => setFieldValue('image', file)}
            config={EVENT_IMAGE_UPLOAD_CONFIG}
          />

          <div className={styles.formActions}>
            <Button
              type="button"
              secondary={true}
              className={styles.cancelButton}
              onClick={() => window.history.back()}
            >
              <FormattedMessage {...messages.reset} />
            </Button>
            <Button type="submit" className={styles.saveButton}>
              <FormattedMessage {...messages.save} />
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
