import React, { useState } from 'react';
import { ForumCategory } from 'types/forum';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';
import styles from './CategoriesSidebar.module.scss';
import classnames from 'classnames';
import { ReactComponent as ChevronIcon } from 'assets/icons/chevron.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

interface CategoriesSidebarProps {
  categories: ForumCategory[];
  selectedCategoryId: string | null;
  onCategorySelect?: (categoryId: string | null) => void;
}

const MailtoLink = () => {
  return (
    <a
      href="mailto:example@example.org?subject=Suggest%20a%20New%20Category&body=Hello,%0D%0A%0D%0AI%20would%20like%20to%20suggest%20a%20new%20category%20for%20the%20forum.%20Here%20are%20the%20details:%0D%0A%0D%0A[Please%20describe%20the%20category%20and%20its%20purpose]%0D%0A%0D%0AThank%20you!">
      network@poloniumfoundation.org
    </a>
  );
};

const CategoryItem: React.FC<{
  category: ForumCategory;
  isExpanded: boolean;
  onToggle: () => void;
}> = ({ category, isExpanded, onToggle }) => {
  return (
    <div className={classnames(styles.categoryItem, isExpanded && styles.expanded)}>
      <button className={styles.categoryButton} onClick={onToggle} type="button">
        <span className={styles.categoryName}>{category.attributes.name}</span>
        <ChevronIcon className={styles.chevronIcon} />
      </button>

      {isExpanded && (
        <div className={styles.categoryContent}>
          {category.attributes.description && (
            <>
              <div className={styles.categoryOpisTitle}>
                <FormattedMessage {...messages.categoryDescription} />:
              </div>
              <p className={styles.categoryDescription}>{category.attributes.description}</p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const CategoryList: React.FC<{
  categories: ForumCategory[];
  expandedCategoryId: string | null;
  onCategoryToggle: (id: string) => void;
}> = ({ categories, expandedCategoryId, onCategoryToggle }) => (
  <>
    {categories.map(category => (
      <CategoryItem
        key={category.id}
        category={category}
        isExpanded={expandedCategoryId === category.id}
        onToggle={() => onCategoryToggle(category.id)}
      />
    ))}
  </>
);

export function CategoriesSidebar({ categories, selectedCategoryId, onCategorySelect }: CategoriesSidebarProps) {
  const [expandedCategoryId, setExpandedCategoryId] = useState<string | null>(selectedCategoryId);
  const [isMobileCategoriesOpen, setIsMobileCategoriesOpen] = useState(false);

  const handleCategoryToggle = (categoryId: string) => {
    if (expandedCategoryId === categoryId) {
      setExpandedCategoryId(null);
      onCategorySelect && onCategorySelect(null);
    } else {
      setExpandedCategoryId(categoryId);
      onCategorySelect && onCategorySelect(categoryId);
      window.history.pushState(null, '', `/forum/categories/${categoryId}/posts`);
    }
  };

  return (
    <div>
      <button className={styles.categoryBar} onClick={() => setIsMobileCategoriesOpen(true)}>
        <span className={styles.categoryBarTitle}>
          <FormattedMessage {...messages.categoryTitle} />
        </span>
        <ChevronIcon className={styles.categoryBarIcon} />
      </button>
      <div className={classnames(styles.mobileCategories, isMobileCategoriesOpen && styles.isOpen)}>
        <div className={styles.mobileCategoriesHeader}>
          <span className={styles.mobileCategoriesTitle}>
            <FormattedMessage {...messages.categoryTitle} />
          </span>
          <button className={styles.closeButton} onClick={() => setIsMobileCategoriesOpen(false)}>
            <CloseIcon />
          </button>
        </div>
        <CategoryList
          categories={categories}
          expandedCategoryId={expandedCategoryId}
          onCategoryToggle={handleCategoryToggle}
        />
        <div className={styles.comprehensiveListMessage}>
          <div className={styles.comprehensiveListBorder}></div>
          <FormattedMessage {...messages.comprehensiveListMessage} />
          &nbsp;
          <MailtoLink />.
        </div>
      </div>
      <aside className={styles.sidebar}>
        <div className={styles.categories}>
          <p className={styles.desktopCategoriesTitle}>
            <FormattedMessage {...messages.categoryTitle} />
          </p>
          <CategoryList
            categories={categories}
            expandedCategoryId={expandedCategoryId}
            onCategoryToggle={handleCategoryToggle}
          />
        </div>
        <div className={styles.comprehensiveListMessage}>
          <div className={styles.comprehensiveListBorder}></div>
          <FormattedMessage {...messages.comprehensiveListMessage} />
          &nbsp;
          <MailtoLink />.
        </div>
      </aside>
    </div>
  );
}
